<template>
  <sagging-template
    :sagging-skin="saggingSkin"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import SaggingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/sagging/SaggingTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const FIELDS_TO_RESET = ['faceSaggingLocations'];
const FIELDS_TO_RESET_ON_NO = ['skinAppearanceDiscomfort'];

const FIELDS = [
  requiredField('saggingSkin'),
  ...FIELDS_TO_RESET.map(field),
  ...FIELDS_TO_RESET_ON_NO.map(field)
];

export default {
  name: 'Sagging',
  components: { SaggingTemplate },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    saggingSkin(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET_ON_NO);
      }

      this.resetStoreFieldsByName(FIELDS_TO_RESET);

      this.showNextStep();
    }
  }
};
</script>
